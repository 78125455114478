
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import { PatientDialogData } from "@/@types/reservation";

@Component({ components: { Button } })
export default class CreateReservationForm extends Vue {
  @Prop({ type: String, default: "" })
  private readonly errorMessage? = "";

  @Prop({ type: Boolean })
  private readonly isShowDialog!: boolean;

  @Prop({ type: Array })
  private readonly listItems!: PatientDialogData[];

  @Prop({ type: String })
  private readonly title!: string;

  private onClickItem(item: PatientDialogData): void {
    this.$emit("select", item);
  }

  private onClickOk(): void {
    this.$emit("ok");
    this.closeDialog();
  }

  private closeDialog(): void {
    this.$emit("update:isShowDialog", false);
  }
}
