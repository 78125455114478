
import { Component } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import CreateReservationForm from "@/components/forms/CreateReservationForm.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import Button from "@/components/Button.vue";

@Component({
  name: "CreateReservation",
  components: {
    CreateReservationForm,
    HospitalHeader,
    ManagementFooter,
    Button,
  },
})
export default class CreateReservation extends CheckCommonLogin {}
