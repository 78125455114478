
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  AddCustomer,
  AddPet,
  AnimalType,
  CustomerPatientData,
  CustomerData,
  NewReservation,
  PatientData,
  PetForm,
  ReservationInfo,
  PossibleReservation,
  PatientDialogData,
} from "@/@types/reservation";
import { PetSex } from "@/constants";
import FacilityModule from "@/store/facility";
import FacilityService from "@/services/facilities";
import { asyncForEach, deleteNullProp, getPhoneNumber3, getPostalCode2 } from "@/utils/Util";
import moment from "moment";
import { CourseData } from "@/@types/course";
import ListDialog from "../dialogs/ListDialog.vue";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";
import FormYmd from "@/components/FormYmd.vue";

@Component({
  components: { ListDialog, Button, FormSet, FormYmd },
})
export default class CreateReservationForm extends Vue {
  private courses = [];

  private routeName = this.$route.name;

  private loading = false;

  private reservationTimesLoading = false;

  private showLabel = false;

  private isRegisteredCustomer = false;
  private isRegisteredPatient = false;
  private isShowCustomerModal = false;
  private isShowPatientModal = false;
  private isSpecialReservation = false;
  private specialTimeMenu = false;
  private duration_min = null;

  private selectedCourse: CourseData | null = null;

  private reservationDate = "";

  private reservationTime = "";

  private reservationTimes: string[] = [];

  selectedPatientId = "";

  selectedCustomerId = "";

  dialogErrorMessage = "";

  get isCustomerFormDisabled(): boolean {
    return this.selectedCustomerId.length > 0;
  }

  get isPatientFormDisabled(): boolean {
    return this.selectedPatientId.length > 0 && this.selectedPatientId !== `_${this.selectedCustomerId}`;
  }

  private onSelectCustomer(item: PatientDialogData): void {
    this.dialogErrorMessage = "";
    this.selectedCustomerId = item.customerId;
    const items = item.items[4].value as { text: string; value: string }[];
    // どうぶつのセレクトボックスアイテムを検索
    if (!items.find((v) => v.value === this.selectedPatientId)) {
      this.selectedPatientId = "";
      this.dialogErrorMessage = "どうぶつを選択してください";
    }
  }

  private onSelectCustomerAndPatient(item: PatientDialogData): void {
    this.selectedCustomerId = item.customerId;
    this.selectedPatientId = item.patientId;
  }

  private get filteredCustomerList(): CustomerPatientData[] {
    // 飼い主情報を取得
    return this.customerPatientList.filter((v) => {
      let res = true;
      if (v.customer.tel) {
        const tel = v.customer.tel.split("-");
        if (tel[0] && this.form.phoneNumber.number1) {
          res = tel[0].indexOf(this.form.phoneNumber.number1) !== -1;
        }
        if (res !== false && tel[1] && this.form.phoneNumber.number2) {
          res = tel[1].indexOf(this.form.phoneNumber.number2) !== -1;
        }
        if (res !== false && tel[2] && this.form.phoneNumber.number3) {
          res = tel[2].indexOf(this.form.phoneNumber.number3) !== -1;
        }
      }
      return res;
    });
  }

  private get filteredPatientList(): CustomerPatientData[] {
    const pet = this.form.pets[0];
    if (!pet.aliasId) {
      return this.customerPatientList;
    }
    // 診察券番号に合致する顧客情報に絞り込み
    return this.customerPatientList.filter((v) => {
      if (v.patient_alias_id) {
        if (this.form.kanaLastName && this.form.kanaFirstName) {
          if (
            v.customer.last_name_kana !== this.form.kanaLastName ||
            this.form.kanaFirstName !== v.customer.first_name_kana
          ) {
            // お名前(カナ)が入力されている場合には飼い主でフィルター
            return false;
          }
        }
        return v.patient_alias_id.toLowerCase().indexOf(pet.aliasId.toLowerCase()) !== -1;
      }
    });
  }

  private get customerListItems(): PatientDialogData[] {
    let list: PatientDialogData[] = [];
    // ダイアログ表示用に整形
    this.filteredCustomerList.forEach((customer: CustomerPatientData) => {
      if (list.find((l) => l.customerId === customer.customer_id)) {
        return;
      }
      // patientListからcustomer_idのペットとってくる
      list.push({
        customerId: customer.customer_id,
        patientId: customer.patient_id,
        items: [
          {
            label: "飼い主名前：",
            value:
              customer.customer.last_name && customer.customer.first_name
                ? `${customer.customer.last_name} ${customer.customer.first_name}`
                : `(${customer.customer.last_name_kana} ${customer.customer.first_name_kana})`,
          },
          {
            label: "メールアドレス：",
            value: customer.customer.email,
          },
          {
            label: "電話番号：",
            value: customer.customer.tel,
          },
          {
            label: "アニコムGo会員：",
            value: customer.customer.member_id ? "会員" : "非会員",
          },
          {
            label: "どうぶつ",
            value: this.getPatientsByCustomer(customer.customer_id),
          },
        ],
      });
    });
    return list;
  }

  private get patientListItems(): PatientDialogData[] {
    let list: { customerId: string; patientId: string; items: { label: string; value: string }[] }[] = [];
    // ダイアログ表示用に整形
    this.filteredPatientList.forEach((patient: CustomerPatientData) => {
      list.push({
        customerId: patient.customer_id,
        patientId: patient.patient_id,
        items: [
          {
            label: "飼い主名前：",
            value:
              patient.customer.last_name && patient.customer.first_name
                ? `${patient.customer.last_name} ${patient.customer.first_name}`
                : `(${patient.customer.last_name_kana} ${patient.customer.first_name_kana})`,
          },
          {
            label: "どうぶつ名：",
            value: patient.patient_name ? patient.patient_name : `(${patient.patient_name_kana})`,
          },
          {
            label: "診察券番号：",
            value: patient.patient_alias_id,
          },
          {
            label: "どうぶつ種：",
            value: patient.patient_animal_type,
          },
        ],
      });
    });
    return list;
  }

  private getPatientsByCustomer(customerId: string): { text: string; value: string }[] {
    let patientList: { text: string; value: string }[] = [];
    this.customerPatientList
      .filter((patient) => patient.customer_id === customerId)
      .forEach((v) => {
        const petName = v.patient_name ? v.patient_name : `(${v.patient_name_kana})`;
        const petType = v.patient_animal_type ? ` / ${v.patient_animal_type}` : "";
        patientList.push({
          text: petName + petType,
          value: v.patient_id,
        });
      });
    patientList.push({ text: "新しい動物を追加", value: "_" + customerId });
    return patientList;
  }

  private reload() {
    location.reload();
  }

  @Watch("reservationDate")
  private async changeReservationDate() {
    this.reservationTime = "";
    this.reservationTimes = [];
    if (this.selectedCourse && this.selectedCourse.id && this.reservationDate) {
      this.reservationTimesLoading = true;
      const year = String(moment(this.reservationDate).get("year"));
      const month = String(moment(this.reservationDate).get("month") + 1);
      const date = String(moment(this.reservationDate).get("date"));
      const res = await FacilityService.getPossibleReservations(this.hospitalId, this.selectedCourse.id, year, month);
      const possibleReservations = res.data.data;
      //選択した日付と同じ予約可能枠をフィルター
      asyncForEach(possibleReservations, (reservation: PossibleReservation) => {
        if (
          year == String(moment(reservation.datetime).get("year")) &&
          month == String(moment(reservation.datetime).get("month") + 1) &&
          date == String(moment(reservation.datetime).get("date")) &&
          reservation.remained_count
        ) {
          this.reservationTimes.push(moment(reservation.datetime).format("HH:mm"));
        }
      });
    }
    this.reservationTimesLoading = false;
  }

  private get hospitalId() {
    return FacilityModule.hospitalId;
  }

  private customerPatientList: CustomerPatientData[] = [];

  private animalTypes: AnimalType[] = [];

  private minuteIncrement = 15;

  private changeCourse(value: CourseData) {
    this.selectedCourse = value;
    if (this.selectedCourse.duration_minute === 15) {
      this.minuteIncrement = 15;
    } else if (this.selectedCourse.duration_minute === 60) {
      this.minuteIncrement = 0;
    } else {
      this.minuteIncrement = 30;
    }
    this.changeReservationDate();
  }

  private async getCustomerPatients() {
    try {
      const res = await FacilityService.getCustomersPatients(this.hospitalId);
      this.customerPatientList = res.data.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private async getCustomerDetail() {
    try {
      this.loading = true;
      const res = await FacilityService.getCustomer(this.hospitalId, this.form.aliasId);
      const customerDetail: CustomerData = res.data.data[0];
      this.form.lastName = customerDetail.last_name;
      this.form.firstName = customerDetail.first_name;
      this.form.kanaLastName = customerDetail.last_name_kana;
      this.form.kanaFirstName = customerDetail.first_name_kana;
      if (customerDetail.email) this.form.email = customerDetail.email;
      if (customerDetail.tel) this.form.phoneNumber = getPhoneNumber3(customerDetail.tel);
      if (customerDetail.zip_code) this.form.postalCode = getPostalCode2(customerDetail.zip_code);
      if (customerDetail.address) this.form.address = customerDetail.address;
      this.newReservation.customer_id = customerDetail.id;
      this.isRegisteredCustomer = true;
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      this.showLabel = true;
      setTimeout(() => {
        this.showLabel = false;
      }, 3000);
      throw new Error(error);
    }
  }
  private async getPatientDetail() {
    try {
      this.loading = true;
      const res = await FacilityService.getPatient(this.hospitalId, this.form.pets[0].aliasId);
      const patientDetail: PatientData = res.data.data[0];
      this.form.pets[0].petName = patientDetail.name;
      this.form.pets[0].kanaPetName = patientDetail.name_kana;
      this.form.pets[0].petType = patientDetail.animal_type;
      this.form.pets[0].petTypeDescription = patientDetail.animal_breed;
      this.form.pets[0].petSex = patientDetail.gender;
      this.form.pets[0].petInsurance = patientDetail.insurance;
      if (patientDetail.birthday) {
        this.form.pets[0].petBirthDay.year = Number(patientDetail.birthday.substr(0, 4));
        this.form.pets[0].petBirthDay.month = patientDetail.birthday.substr(4, 2);
        this.form.pets[0].petBirthDay.day = patientDetail.birthday.substr(6, 2);
      }
      this.newReservation.patient_ids?.push(patientDetail.id);
      this.isRegisteredPatient = true;
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      this.showLabel = true;
      setTimeout(() => {
        this.showLabel = false;
      }, 3000);
      throw new Error(error);
    }
  }

  private async getCourses() {
    try {
      const res = await FacilityService.getCourses(this.hospitalId);
      this.courses = res.data.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  // private async getCustomer() {
  //   try {
  //     const res = await FacilityService.getCustomer(this.hospitalId, this.form.aliasId)
  //   } catch (error) {

  //   }
  // }

  private mounted() {
    this.getCustomerPatients();
    this.getCourses();
    this.getAnimalTypes();
  }

  private newReservation: NewReservation = {
    member_id: undefined,
    pet_ids: [],
    customer_id: "",
    patient_ids: [],
    datetime: "",
    course_id: "",
    message: "",
    memo: "",
    memo_internal: "",
  };

  // private defaultPetInfo: PetForm = {
  //   petName: "",
  //   kanaPetName: "",
  //   petType: "",
  //   petSex: undefined,
  //   petBirthDay: {
  //     year: undefined,
  //     month: undefined,
  //     day: undefined,
  //   },
  //   petTypeDescription: "",
  //   petInsurance: "",
  // };
  private form: ReservationInfo = {
    aliasId: "",
    lastName: "",
    firstName: "",
    kanaLastName: "",
    kanaFirstName: "",
    email: "",
    phoneNumber: {
      number1: "",
      number2: "",
      number3: "",
    },
    postalCode: {
      number1: "",
      number2: "",
    },
    address: "",
    pets: [
      {
        petName: "",
        kanaPetName: "",
        petType: "",
        petSex: undefined,
        petBirthDay: {
          year: "",
          month: undefined,
          day: undefined,
        },
        petTypeDescription: "",
        petInsurance: "",
        aliasId: "",
      },
    ],
  };

  private zipValidateOn = false;
  @Watch("form.postalCode", { deep: true })
  private changeZipValidateOn() {
    this.zipValidateOn = true;
    if (!this.form.postalCode.number1 && !this.form.postalCode.number2) {
      this.zipValidateOn = false;
    }
  }

  // private addPetInfo() {
  //   this.form.pets.push(this.defaultPetInfo);
  // }
  private deletePet(index: number) {
    this.form.pets.splice(index, 1);
  }
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async newReservationFactory() {
    this.newReservation.datetime = moment(this.reservationDate)
      .hour(Number(this.reservationTime.slice(0, 2)))
      .minute(Number(this.reservationTime.slice(3, 5)))
      .second(0)
      .format();
    if (this.selectedCourse) this.newReservation.course_id = this.selectedCourse.id;
    if (!this.newReservation.customer_id) {
      const newCustomer: AddCustomer = {
        first_name: this.form.firstName,
        first_name_kana: this.form.kanaFirstName,
        last_name: this.form.lastName,
        last_name_kana: this.form.kanaLastName,
        email: this.form.email ? this.form.email : undefined,
        tel: `${this.form.phoneNumber.number1}-${this.form.phoneNumber.number2}-${this.form.phoneNumber.number3}`,
        zip_code:
          this.form.postalCode.number1 && this.form.postalCode.number2
            ? `${this.form.postalCode.number1}-${this.form.postalCode.number2}`
            : "",
        address: this.form.address,
        alias_id: this.form.aliasId,
      };
      const newCustomerPayload = deleteNullProp(newCustomer);
      const createCustomerRes = await FacilityService.createCustomer(this.hospitalId, newCustomerPayload);
      this.newReservation.customer_id = createCustomerRes.data.data[0].id;
    }
    if (this.form.pets.length && !this.newReservation.patient_ids?.length) {
      await asyncForEach(this.form.pets, async (pet: PetForm) => {
        const newPatient: AddPet = {
          name: "",
          name_kana: "",
          gender: "",
          animal_type: "",
          animal_breed: "",
          insurance: "",
          birthday: "",
          customer_id: this.newReservation.customer_id!,
          alias_id: pet.aliasId,
        };
        newPatient.name = pet.petName;
        newPatient.name_kana = pet.kanaPetName;
        if (pet.petSex) newPatient.gender = pet.petSex;
        newPatient.animal_type = pet.petType;
        newPatient.animal_breed = pet.petTypeDescription;
        newPatient.insurance = pet.petInsurance;

        if (pet.petBirthDay.year) {
          newPatient.birthday = `${pet.petBirthDay.year}${pet.petBirthDay.month ? pet.petBirthDay.month : "00"}${
            pet.petBirthDay.day ? pet.petBirthDay.day : "00"
          }`;
        } else {
          newPatient.birthday = "";
        }

        const newPatientPayload = deleteNullProp(newPatient);
        try {
          const res = await FacilityService.createPatient(this.hospitalId, newPatientPayload);
          const petId = res.data.data[0].id;
          this.newReservation.patient_ids?.push(petId);
        } catch (error: any) {
          throw new Error(error);
        }
      });
    }
  }

  private async getAnimalTypes() {
    try {
      const res = await FacilityService.getAnimalTypes();
      this.animalTypes = res.data.data;
      const otherIndex = this.animalTypes.findIndex((item) => item.animal_type === "その他");
      this.animalTypes.push(this.animalTypes.splice(otherIndex, 1)[0]);
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private async submit() {
    try {
      this.loading = true;
      await this.newReservationFactory();
      if (!this.newReservation.member_id) {
        delete this.newReservation.member_id;
      }
      if (!this.newReservation.customer_id) {
        delete this.newReservation.customer_id;
      }
      const payload = deleteNullProp(this.newReservation);
      if (!this.isSpecialReservation) {
        await FacilityService.createReservation(this.hospitalId, payload);
      } else {
        await FacilityService.createSpecialReservation(this.hospitalId, {
          ...payload,
          duration_min: Number(this.duration_min),
        });
      }
      this.showModal("created");
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      this.showModal("error");
      throw new Error(error);
    }
  }

  private async fillOutForm(): Promise<void> {
    // 顧客情報取得
    const customerRes = await FacilityService.getCustomer(this.hospitalId, this.selectedCustomerId);
    const customerData = customerRes.data.data[0];

    // 顧客情報入力
    this.newReservation.customer_id = customerData.id;
    this.form.lastName = customerData.last_name;
    this.form.firstName = customerData.first_name;
    this.form.kanaLastName = customerData.last_name_kana;
    this.form.kanaFirstName = customerData.first_name_kana;
    this.form.email = customerData.email;
    if (customerData.tel) {
      const tel = customerData.tel.split("-");
      this.form.phoneNumber.number1 = tel[0];
      this.form.phoneNumber.number2 = tel[1];
      this.form.phoneNumber.number3 = tel[2];
    }
    if (customerData.zip_code) {
      const zip = customerData.zip_code.split("-");
      this.form.postalCode.number1 = zip[0];
      this.form.postalCode.number2 = zip[1];
    }
    this.form.address = customerData.address;

    if (this.selectedPatientId === `_${this.selectedCustomerId}`) {
      // 新規作成するのでクリア
      this.form.pets[0].aliasId = "";
      this.form.pets[0].petName = "";
      this.form.pets[0].kanaPetName = "";
      this.form.pets[0].petType = "";
      this.form.pets[0].petTypeDescription = "";
      this.form.pets[0].petSex = undefined;
      this.form.pets[0].petBirthDay.year = "";
      this.form.pets[0].petBirthDay.month = undefined;
      this.form.pets[0].petBirthDay.day = undefined;
      this.form.pets[0].petInsurance = "";
      return;
    } else {
      // ペット情報取得
      const patientRes = await FacilityService.getPatient(this.hospitalId, this.selectedPatientId);
      const patientData = patientRes.data.data[0];

      this.newReservation.patient_ids?.push(patientData.id);
      this.form.pets[0].aliasId = patientData.alias_id;
      this.form.pets[0].petName = patientData.name;
      this.form.pets[0].kanaPetName = patientData.name_kana;
      this.form.pets[0].petType = patientData.animal_type;
      this.form.pets[0].petTypeDescription = patientData.animal_breed;
      this.form.pets[0].petSex = patientData.gender;
      if (patientData.birthday) {
        this.form.pets[0].petBirthDay.year = patientData.birthday.slice(0, 4);
        this.form.pets[0].petBirthDay.month = patientData.birthday.slice(4, 6);
        this.form.pets[0].petBirthDay.day = patientData.birthday.slice(6, 8);
      }
      this.form.pets[0].petInsurance = patientData.insurance;
    }
    this.isShowPatientModal = false;
  }

  private get petSex() {
    return PetSex;
  }

  get phoneNumber(): string {
    let tel = "";
    if (this.form.phoneNumber.number1) {
      tel = this.form.phoneNumber.number1;
      if (this.form.phoneNumber.number2) {
        tel += `-${this.form.phoneNumber.number2}`;
      }
      if (this.form.phoneNumber.number3) {
        tel += `-${this.form.phoneNumber.number3}`;
      }
    } else if (this.form.phoneNumber.number2) {
      tel = this.form.phoneNumber.number2;
      if (this.form.phoneNumber.number3) {
        tel += `-${this.form.phoneNumber.number3}`;
      }
    } else if (this.form.phoneNumber.number3) {
      tel = this.form.phoneNumber.number3;
    }
    return tel;
  }
}
